.acumin-pro-bold {
    font-family: acumin-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
}

.acumin-pro-regular {
    font-family: acumin-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
}

p,
a,
ul,
li {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;

    @apply .text-navy;


    @media (max-width: 720px) {
        font-size: 15px;
        line-height: 22px;
    }

    @media (max-width: 555px) {
        font-size: 14px;
        line-height: 22px;
    }
}




.bold {
    font-weight: 700;
}

.large-title {
    font-size: 40px;

    @media (max-width: 720px) {
        font-size: 36px;
    }

    @media (max-width: 555px) {
        font-size: 32px;
    }

    @media (max-width: 440px) {
        font-size: 26px;
    }

    @media (max-width: 320px) {
        font-size: 22px;
    }

    &.bold {
        font-weight: 700;
    }
}

.medium-title {
    font-size: 34px;

    @media (max-width: 720px) {
        font-size: 32px;
    }

    @media (max-width: 555px) {
        font-size: 28px;
    }

    @media (max-width: 440px) {
        font-size: 26px;
    }

    @media (max-width: 320px) {
        font-size: 22px;
    }

    &.bold {
        font-weight: 700;
    }

}

.small-title {
    font-size: 30px;
    line-height: 1.2;

    @media (max-width: 720px) {
        font-size: 28px;
    }

    @media (max-width: 555px) {
        font-size: 26px;
    }

    @media (max-width: 440px) {
        font-size: 24px;
    }

    @media (max-width: 320px) {
        font-size: 22px;
    }

    &.bold {
        font-weight: 700;
    }
}

.sub-heading {
    font-size: 16px;


    &.bold {
        font-weight: 700;
    }
}

.contact-content {

    a {
        @apply .text-white;

        &:hover {
            text-decoration: underline;
        }
    }

    p {
        @apply .text-white;
    }
}

.subtext{
    a{
        text-decoration: underline;
    }
}