$title-font-family: "Helvetica";
$standard-font-family: "Helvetica";

$title-font-book: 400;
$title-font-medium: 500;
$title-font-heavy: 700;

$standard-font-regular: 400;
$standard-font-medium: 500;
$standard-font-semibold: 600;
$standard-font-bold: 700;

$container: 1500px;
$container_small: 1500px;

$yellow: #ffe700;
$gold: $yellow;
$red: #b6051a;
$blue: #20609b;
$lightblue: #069afe;



$imgpath: "../../../../assets/img/";
