header {
  background-color: white;
  font-family: $title-font-family;
  text-transform: uppercase;
  height: 78px;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;

  &.home-nav {
    border-bottom: 0;
  }

  .red-menu-area {
    @apply .w-1/6 .h-full .bg-red items-center flex justify-center;
    border: none;

    @media (max-width: 800px) {
      @apply .w-1/4;
    }

    @media (max-width: 600px) {
      @apply .w-1/3;
    }

    @media (max-width: 480px) {
      @apply .w-1/2;
    }

    img {
      @apply .px-4;
      margin: 0 auto;
      min-width: 120px;
    }
  }

  .white-menu-area {
    @apply .w-4/6 .h-full .bg-white .acumin-pro-regular items-center flex justify-end;

    ul {
      li {
        a {
          padding: 26px 0;

          &.active {
            border-bottom: 2px solid #ee0000 !important;

            position: relative;
            z-index: 20;
          }
        }

        &.contact-link {
          .navy-menu-area {
            @apply .h-full .bg-navy .acumin-pro-bold items-center flex justify-center;
            border: none;
            padding: 0 2rem;



            @media (max-width: 840px) {
              display: none !important;

            }
          }

        }
      }
    }

    @media (max-width: 840px) {
      display: none !important;

    }
  }









}

#mobile-burger-btn {
  cursor: pointer;
  display: none;
  height: 38px;
  margin: 0;
  text-align: center;
  transition: right 0.5s;
  width: 40px;
  z-index: 100;
  border-radius: 0;
  color: #162538;

  &.menu-open {
    color: white;
  }

  @media (max-width: 840px) {
    display: inline-block;
    position: fixed;
    right: 5%;
    top: 20px;
    z-index: 9999;

  }

  i {
    font-size: 28px;
    position: relative;
    top: 5px;

    &:nth-child(2) {
      display: none;
    }
  }

  &.menu-open {
    i {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        display: inline-block;
      }
    }
  }
}