.project-carousel-outter {


    /* the slides */
    .slick-slide {
        margin: 0 27px;
    }

    /* the parent */
    .slick-list {
        margin: 0 -27px;
    }
}


.carousel-block {
    width: 100%;
    padding-top: 30px;


}


.caurosel-buttons {
    display: flex;
    position: absolute;
    left: -60px;
    top: 35%;
    cursor: pointer;

    @media (max-width: 1200px) {
        left: -40px;
    }

    @media (max-width: 899px) {
        display: none;
    }

    @media (max-width: 600px) {
        left: 0;
        bottom: -12.5%;
        top: auto;
    }

    .carousel-button-left {
        height: 60px;
        width: 60px;
        background-color: #162538;

        @media (max-width: 1200px) {
            height: 40px;
            width: 40px;
        }

    }

    .carousel-button-right {
        height: 60px;
        width: 60px;
        background-color: #EE0000;

        @media (max-width: 1200px) {
            height: 40px;
            width: 40px;
        }

    }
}