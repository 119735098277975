.about-section {
    p {
        margin-bottom: 20px;
        @apply .text-navy;
    }

    h3 {
        margin-bottom: 20px;
        @apply .text-navy .bold;
        @extend .medium-title;
    }
}