.map {
  height: 585px;
}



.contact-paragraph {
  p {
    margin-bottom: 20px !important;
    color: #A4AAB0;
  }
}
.contact-content {
  h3 {
    padding: 30px 0 10px;;
    color: #fff;
    font-weight: bold;
    font-size: 26px;
  }
}

.contact-map-area {
  margin-top: 20px;
  position: relative;

  .map-area {

    width: 85%;

    @media (max-width: 900px) {
      width: 100%;
    }

  }

  .red-cta-block {
    //height: 360px;
    width: 400px;
    background-color: #EE0000;
    position: absolute;
    right: 0;
    top: 19%;

    @media (max-width: 900px) {
      height: auto;
      width: 260px;
      position: absolute;
      right: 0;
      bottom: 0;
      top: auto;
    }

    @media (max-width: 450px) {
      width: auto;
      position: relative;
      left: auto;
      right: auto;
      bottom: auto;
      top: auto;
    }

    .txt-wrap {
      padding: 20%;

      @media (max-width: 900px) {
        padding: 10%;
      }
    }

  }
}