.navy-slider-block {
    height: 48rem;

    @media (max-width: 555px) {
        display: none;
    }
}

.slide-wrap {
    @apply .w-5/6;

    @media (max-width: 555px) {
        @apply .w-full;
    }


    .slideshow {
        position: relative;



        .slide {
            height: 48rem;
            max-height: 800px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex !important;
            align-items: center;
            line-height: 1.25;
            background-color: #131313;

            @media (max-width: 450px) {
                flex-direction: column;
                height: auto;
            }

            .image {
                position: absolute;
                top: 0;
                width: 100%;
                height: 48rem;

                @media (max-width: 450px) {
                    width: 100%;
                    position: relative;
                    left: auto;
                    right: auto;
                    bottom: auto;
                    top: auto;
                    max-height: 52vw;
                }

                img,
                video {
                    display: block;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            &.video {}

            &:after {
                content: " ";
                background-image: linear-gradient(180deg, #0A1A31 99%, rgba(0, 0, 0, 0.5) 99%);
                opacity: 10%;
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: red;

            }


            .slide-block {
                background: #FFFFFF;
                width: 550px;
                height: 420px;
                box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.50);
                display: flex;
                justify-content: center;
                position: relative;
                left: -14%;
                z-index: 10;

                @media (max-width: 555px) {
                    left: 0;
                    margin: 0 auto;
                    width: 400px;
                    height: auto;
                    padding: 40px 10px;
                }

                @media (max-width: 450px) {
                    width: 100%;
                    position: relative;
                    left: auto;
                    right: auto;
                    bottom: auto;
                    top: auto;
                    box-shadow: none;
                }


                .inner-wrap {
                    align-items: center;
                    display: flex;


                    .txt-wrap {
                        width: 85%;
                        text-align: left;
                        position: relative;
                        z-index: 1000;
                        align-items: center;
                        margin: 0 auto;

                        @media (max-width: 1026px) {
                            width: 100%;
                        }

                        p {
                            margin-top: 20px;
                            color: #162538;
                        }


                    }
                }
            }

        }
    }
}

.red-video-block {
    height: 140px;
    width: 400px;
    position: absolute;
    right: 0;
    top: -70px;
    display: flex;
    align-items: center;

    @media (max-width: 450px) {
        width: auto;
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
        top: auto;
    }

    @media (max-width: 400px) {
        display: none;
    }

    .play-button {
        background-color: white;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        position: absolute;
        left: 16%;

        .play-icon {
            height: 16px;
            width: 14px;
            position: absolute;
            left: 37%;
            top: 30%;
        }
    }

    .video-thumbnail-image {
        margin-left: 30px;

        img {
            width: 110px;
            height: 110px;
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 30px;
        }
    }

    h5 {
        font-size: 18px;
        font-weight: 700;
    }

    p {
        color: #F7B1B1;
    }

}