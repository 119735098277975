.projects-pagination-block {
    display: flex;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.50);
    margin: 0 auto;
    width: fit-content;

    @media (max-width: 470px) {
        display: none;
    }


    .left-area {
        @apply .bg-white;
        height: 60px;
        margin: 0 auto;
        align-items: center;
        display: flex;
        padding: 0px 20px;
    }

    .mid-area {
        @apply .bg-navy;
        width: 60px;
        height: 60px;
    }

    .right-area {
        @apply .bg-white;
        height: 60px;
        margin: 0 auto;
        align-items: center;
        display: flex;
        padding: 0px 20px;
    }
}


.project-details {
    margin-top: 20px;
    position: relative;

    .project-image {
        width: 85%;

        @media (max-width: 840px) {
            width: 100%;
        }

        img {
            width: 85%;

            @media (max-width: 900px) {
                width: 100%;
            }
        }
    }

    .red-cta-block {
        //height: 360px;
        width: 400px;
        background-color: #EE0000;
        position: absolute;
        right: 0;
        top: -20%;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.50);
        display: flex;

        @media (max-width: 900px) {
            height: auto;
            width: 260px;
            position: absolute;
            right: 0;
            bottom: 0;
            top: auto;
        }

        @media (max-width: 840px) {
            width: auto;
            position: relative;
            left: auto;
            right: auto;
            bottom: auto;
            top: auto;
        }

        .txt-wrap {
            padding: 10%;
            align-items: center;
            margin: 0 auto;

            @media (max-width: 900px) {
                padding: 10%;
            }
        }

    }

    .image-pagination {
        position: absolute;
        bottom: 0;
        right: 15%;

        @media (max-width: 900px) {
            bottom: 0;
            top: auto;
            right: auto;
            left: 0;
        }

        @media (max-width: 470px) {
            bottom: auto;
            top: 0;

        }



        .image-arrows {
            display: flex;
            width: 120px;
            justify-content: center;
            @media (max-width: 470px) {
                width: 70px;
                
            }
            .left-button {
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    margin: 0 auto;
                    height: 12px;
                    width: 20px;
                }
            }

            .right-button {
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    margin: 0 auto;
                    height: 12px;
                    width: 20px;
                }
            }
        }
    }
}

.thumbnail-wrap {
    .thumb-image {

        img {
            width: 100px;
            margin-right: 1rem;
            @apply opacity-50;

            &.slick-current {
                @apply opacity-100;
            }
        }
    }
}


.filter-list {
    a {
        @apply .text-lightnavy .sub-heading .bold;

        &.active {
            @apply .text-red;

        }

        &:before {
            content: "/";
            @apply .text-lightnavy;
            padding: 0px 5px;
        }

        &:first-child {
            &:before {
                display: none;
            }
        }
    }




}