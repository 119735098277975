footer {
  background-color: #162538;

  .footer-left {
    @apply .w-1/2;


    a {
      @apply .text-red;
      font-size: 30px;
      line-height: 40px;

      @media (max-width: 720px) {
        font-size: 28px;
      }

      @media (max-width: 555px) {
        font-size: 26px;
      }

      @media (max-width: 440px) {
        font-size: 24px;
      }

      @media (max-width: 320px) {
        font-size: 22px;
      }

      &:hover {
        @apply .underline;
      }

    }

    @media (max-width: 800px) {
      @apply .w-full;
    }



    .logo {
      img {
        width: 75px;

      }
    }
  }

  .footer-right {
    @apply .w-1/2;

    @media (max-width: 800px) {
      @apply .w-full;
    }



    .social-media-items {
      a {
        text-decoration: none;




        .fab {
          color: #fff;
          margin-right: 8px;
          text-decoration: none;
          transition: all .1s ease;

          &:hover {
            color: #EE0000;
          }
        }
      }
    }

    .fg {
      flex: 1 0 auto;

      ul {
        li {
          a {
            &:hover {
              @apply .underline;
            }
          }
        }
      }
    }

    .btm {
      .inner-wrap {
        display: flex;
        font-size: 13px;
        color: #a6abb1;
        letter-spacing: 0;
        line-height: 1.35;
        width: 100%;

        .col {
          @media (max-width: 1230px) {
            padding: 10px 0;
          }
        }

        @media (max-width: 1230px) {
          flex-direction: column;
          flex-wrap: nowrap;
          text-align: left;
          padding: 12.5px 0;
        }

        @media (max-width: 465px) {
          flex-direction: column;
          flex-wrap: nowrap;
          text-align: left;
          padding: 12.5px 0;
        }

        .col {
          &:nth-child(1) {
            flex-grow: 1;
          }

          @media (max-width: 465px) {
            padding: 2.5px 0;
          }
        }

        a,
        p {
          color: #a6abb1;
          font-size: 13px;
        }


      }
    }
  }



}