.full-width-image {
    position: relative;
    padding-top: 5rem;

    @media (max-width: 600px) {
        padding-top: 0;
    }


    .bck-image {
        background: url('/assets/img/full-width-image-update.jpg') no-repeat center center fixed;
        background-size: cover;
        width: 100%;
        min-height: 50vw;
        position: absolute;
        display: block;
        z-index: 1;
        top: 0;
        left: 0;

    }

    .white-area {
        background-color: white;
        margin: 0 0 0 5%;
        z-index: 10;
        height: 100%;
        min-height: 50vw;
        position: relative;

        .pad-y {
            @apply .py-20;

            @media (max-width: 600px) {
                @apply .py-0;
            }
        }

        @media (max-width: 600px) {
            margin: 0;
        }
    }



}