.card {
    margin: 20px 0;
    padding: 30px 40px;
    transition: all .3s ease;


    &:hover {
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.10);
        transform: scale(1.05, 1.05);

        .image-area {
            .border-area {
                border-bottom: 2px solid #EE0000;
                width: 100%;
                margin-left: 5px;
                position: relative;

                &::after {
                    background: url('/assets/img/arrow-point.png') no-repeat;
                    content: " ";
                    height: 16px;
                    width: 24px;
                    position: absolute;
                    margin-left: -10px;
                    background-size: 10px;
                    right: -17px;
                    top: 51px;
                }
            }
        }
    }

    .image-area {
        display: flex;

        .border-area {
            border-bottom: 2px solid #B9BDC3;
            width: 100%;
            margin-left: 5px;



        }

        .icon {
            img {
                width: 60px;
                height: 60px;
            }
        }
    }

    .inner-wrap {
        margin-left: 0;

        .txt-wrap {
            .medium-title {
                line-height: 34px;
                margin: 30px 0;
            }

            ul {
                li {
                    padding-bottom: 10px;
                }
            }
        }
    }

}