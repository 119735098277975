.minus-margin-grid {
  margin: 0px -40px;

}

.grid {
  @apply .flex .flex-wrap .-mx-4;

  &[data-columns="2"] {
    >div {
      @apply .w-1/2 .px-4 .mb-8;



      @media (max-width: 680px) {
        @apply .w-full .mb-8;

      }
    }
  }

  &[data-columns="3"] {
    >div {
      @apply .w-1/3 .px-4 .mb-8;

      @media (max-width: 900px) {
        @apply .w-1/2 .mb-8 .px-4;
      }

      @media (max-width: 600px) {
        @apply .w-full .mb-8;
      }
    }
  }

  &[data-columns="4"] {
    >div {
      @apply .w-1/4 .px-4 .mb-8;
    }
  }

  &[data-columns="5"] {
    >div {
      @apply .w-1/5 .px-4 .mb-8;
    }
  }

  &[data-columns="all"] {
    >div {
      @apply .flex-1 .px-4 .mb-8;
    }
  }
}