.fleet-cta {
    margin-top: 20px;
    position: relative;

    .fleet-image {

        img,
        video {
            max-height: 660px;
            width: 85%;
            object-fit: cover;

            @media (max-width: 900px) {
                width: 100%;
            }
        }
    }

    .red-cta-block {
        //height: 360px;
        width: 400px;
        background-color: #EE0000;
        position: absolute;
        right: 0;
        bottom: 15%;

        @media (max-width: 900px) {
            height: auto;
            width: 260px;
            position: absolute;
            right: 0;
            bottom: 0;
            top: auto;
        }

        @media (max-width: 450px) {
            width: auto;
            position: relative;
            left: auto;
            right: auto;
            bottom: auto;
            top: auto;
        }

        .txt-wrap {
            padding: 10%;
            padding-top: 25%;

            @media (max-width: 900px) {
                padding: 10%;
            }

            .play-button {
                background-color: white;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                position: absolute;

                @media (max-width: 900px) {
                    display: none;
                }

                .play-icon {
                    height: 16px;
                    width: 14px;
                    position: absolute;
                    left: 37%;
                    top: 30%;
                }
            }
        }



    }
}

.fleet-list,
.about-section {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        padding: 5px 0;
        padding-left: 1em;
        text-indent: -1em;
        @apply .text-navy;
    }

    li:before {
        content: "■";
        padding-right: 7px;
    }

    p {
        @apply .text-navy;
    }
}