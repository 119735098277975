input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

form {
  width: 100%;
}

label {
  display: none;

  &.show {
    display: block;
    font-size: 24px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 24px;
  }
}

input,
textarea,
select {
  width: 100%;
  border-radius: 0;
  margin: 10px 0;
  font-size: 16px;
  padding: 18px 25px;
  letter-spacing: 0;
  line-height: 1.35;
  color: #fff;
  background: #0F1927;

  &.required {
    border: 1px solid #8b0000;
  }

  &.form-control {
    border-radius: 0;
  }
}

input::placeholder {
  color: #fff;
}

textarea {
  min-height: 234px;
}

textarea::placeholder {
  color: #fff;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  -webkit-appearance: none;
}

.dialog-item {
  button {
    margin-top: 10px;
  }
}

.validation-msg {
  padding: 20px 25px;
  margin: 10px auto;
  text-align: center;
  color: #262626;
  border: 1px solid #e0d8d8;
  border-radius: 3px;
  line-height: 1;
  background: #ffffcc;

  &.error {
    background: #f7e0e0;
  }

  @media (max-width: 900px) {
    padding: 15px 20px;
  }

  @media (max-width: 600px) {
    padding: 10px 15px;
  }

  @media (max-width: 450px) {
    padding: 7.5px 10px;
  }
}

.select_style {
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  background: #f4fbfd;
  border-radius: 3px;
  font-family: $standard-font-family;
  font-size: 14px;
  width: 100%;
  position: relative;
  border: 1px solid #d0cfcf;
  margin-bottom: 9px;
  font-weight: 700;
  color: #6f6f6f;

  @media (max-width: 900px) {
    font-size: 15px;
    line-height: 1em;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 1em;
  }

  @media (max-width: 450px) {
    font-size: 12px;
    line-height: 1em;
  }

  &:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    color: #909090;
    top: 6.5px;
    right: 10px;
    pointer-events: none;
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    width: 100%;

    option {
      padding-right: 45px;
    }
  }
}

.error-input,
div.error-input {
  border: 1px $red solid;
}

.error-msg {
  font-size: 14px;
  padding: 5px 0;
  display: none;
  color: $red;
  text-align: right;
}