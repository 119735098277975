.partnership-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2rem;

    >a {
        background: #F3F4F5;
        padding: 3rem;
        display: grid;
        place-items: center;
        height: 260px;
        -webkit-transition-duration: 0.3s;
        -webkit-transition-timing-function: ease;

        &:hover {
            transform: scale(1.05);

        }

        img {
            width: 100%;


        }
    }
}