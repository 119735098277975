.mobile-menu-wrap {
  max-width: 354px;
  padding: 25px 50px;
  background-color: #162538;
  height: 100%;

  .top {
    img {
      max-width: 100px;
      width: 50px;
    }
  }

  .mobile-menu-text {


    p,
    a {
      color: white;
      font-size: 11px;
    }
  }
}

.social-media-items {


  a {
    text-decoration: none;

    .fab {
      color: #fff;
      margin-right: 8px;
      text-decoration: none;

      &:hover {
        color: #EE0000;
      }
    }
  }
}

.mid {
  flex: 1 0 auto;
}