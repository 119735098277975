.button {

    a,
    button {
        &:hover {
            h5 {
                @apply .underline;
            }
        }


        .arrow-image {
            width: 2.5em;
            margin-left: 10px;
            max-height: 20px;


            &.left {
                margin-left: 0;
                margin-right: 10px;
            }

            &.contact-arrow {

                @media (max-width: 1040px) {
                    display: none;
                }
            }

        }

        // &:hover {
        //     .arrow-image {
        //         margin-left: 20px;
        //         transition: all 0.3s ease 0s;
        //         transition-duration: 0.1s;
        //         transition-timing-function: linear;


        //         &.left {
        //             margin-left: 0;
        //             margin-right: 20px;
        //         }
        //     }
        // }
    }
    &.disabled{
        pointer-events: none;
        opacity: .25;
    }
}