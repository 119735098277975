section.regular {
  position: relative;
}

.inner-wrap {
  max-width: $container;
  width: 90%;
  margin: auto;
}

.page-mid {
  .inner-wrap {
    padding: 50px 0;
  }
}
