.heading-area {
    width: 40%;

    @media (max-width: 1600px) {
        width: 50%;
    }

    @media (max-width: 1200px) {
        width: 60%;
    }

    @media (max-width: 900px) {
        width: 80%;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
}